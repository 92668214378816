import { useRouter } from 'next/router';
import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import isMobile from 'ismobilejs';
import classNames from 'classnames';
import NavBar from './navBar/navBar';
import ReactHeap from '../utils/heap';
import { HEAP_ID } from '../buildConfig/processEnv';
import { useAppContext } from '../contexts/appContext';
import Login from './login';
import AppLoading from './appLoading';
import RegisterForm from './userDetails';
import MobileNavBar from './MobileNavBar';
import useColorScheme from '../hooks/useColorScheme';

const DETAIL_OPTIONS = ['true', 'skip'];

function Layout({
    children,
    title,
    className,
    style = {},
}: {
    children: React.ReactNode;
    title: string;
    className?: string;
    style?: React.CSSProperties;
}) {
    const { user, fetchingToken, handleUser, verifyToken } = useAppContext();
    const { colorScheme } = useColorScheme();
    const [isDetails, setIsDetails] = useState<boolean>(false);

    useEffect(() => {
        if (HEAP_ID) ReactHeap.initialize(HEAP_ID);
    }, []);

    // ***
    // *** Disabling this workflow for now as we are not looking to collect user details.
    // ***
    // useEffect(() => {
    //     if (!user || !user?.showUserDetails) return;
    //     function checkDetails() {
    //         const timeout = Number(localStorage.getItem('detailsTimeout'));
    //         if (timeout && timeout !== moment().day()) {
    //             localStorage.removeItem('detailsSubmitted');
    //             localStorage.removeItem('detailsTimeout');
    //         }
    //         if (
    //             !DETAIL_OPTIONS.includes(localStorage.getItem('detailsSubmitted')) &&
    //             user?.showUserDetails
    //         ) {
    //             setIsDetails(true);
    //         }
    //     }
    //     checkDetails();
    // }, [router, user, user?.showUserDetails]);

    // // ? Possible Fix for having the login screen showing up randomly.
    // useEffect(() => {
    //     const userDataLocalStorage = localStorage.getItem('user-data');
    //     const userDataToken = localStorage.getItem('token');
    //     if (!user?.id && userDataLocalStorage) {
    //         verifyToken(userDataToken);
    //         // ? [[[Optimistic UI]]]
    //         handleUser({
    //             ...JSON.parse(userDataLocalStorage),
    //             token: userDataToken,
    //             isLoggedIn: !!userDataToken,
    //         });
    //     }
    // }, [handleUser, user?.id, verifyToken]);

    const mobile = isMobile().phone;

    if (fetchingToken) return <AppLoading>Authenticating user</AppLoading>;
    if (!user || !user.token || !user.isLoggedIn) return <Login />;
    if (!user?.groups) return <AppLoading>Loading user data</AppLoading>;

    return (
        <div data-mode={colorScheme}>
            <Head>
                <title>{title}</title>
                <link rel="shortcut icon" href="images/favicon.png" />
            </Head>
            {/* <RegisterForm setIsDetails={setIsDetails} isDetails={isDetails} /> */}
            {!isDetails && (
                <div className="flex">
                    {/* // ? Product Intelligence Filter's zIndex is 301 */}
                    <div
                        className={classNames('fixed z-[400]', {
                            hidden: mobile,
                        })}
                    >
                        <NavBar />
                    </div>
                    {mobile && <MobileNavBar />}
                    <div
                        style={style}
                        className={classNames(`p-4 ml-20 grow`, className, {
                            '!ml-0 max-w-[100dvw] overflow-auto': mobile,
                        })}
                    >
                        {children}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Layout;
