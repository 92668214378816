import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { hotjar } from 'react-hotjar';
import { API_URL } from '../buildConfig/processEnv';
import type { UserGroup, UserType } from '../types/user';
import { getNextUrl } from '../utils/urls';

type AppContextType = {
    user: UserType;
    handleUser: (user: UserType, reroute?: boolean) => void;
    verifyToken: (token: string) => Promise<void>;
    googleLogin: () => void;
    microsoftLogin: () => void;
    credentialLogin: ({
        username,
        password,
    }: {
        username: string;
        password: string;
    }) => Promise<string>;
    logOut: (token: string) => void;
    fetchingToken?: boolean;
};

type CredentialLogin = {
    user: {
        id: number;
        username: string;
        email: string;
        first_name: string;
        last_name: string;
        groups: UserGroup[];
        show_user_details_prompt: boolean;
    };
};

const AppContext = createContext<AppContextType>({
    handleUser(user) {},
    verifyToken(token: string) {
        return Promise.reject(new Error());
    },
    googleLogin() {},
    microsoftLogin() {},
    logOut() {},
    user: {},
    credentialLogin: async ({ username, password }) => '',
});
AppContext.displayName = 'AppContext';

function AppContextProvider({ children }: { children: React.ReactNode }) {
    const { isReady, query, ...router } = useRouter();
    const currentRouteRef = React.useRef<string>();
    const [user, setUser] = React.useState<AppContextType['user']>({ isLoggedIn: false });

    const handleUser = useCallback<AppContextType['handleUser']>(newUserData => {
        setUser(prev => ({ ...prev, ...newUserData }));
    }, []);

    const generateUserToken = useCallback(async () => {
        const response = await fetch(`${API_URL}/auth-service/api/auth/tokens/generate/`, {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            credentials: 'include',
        });

        if (response.ok) {
            const { token } = await response.json();
            setUser(prev => ({ ...prev, token, isLoggedIn: true }));
            localStorage.setItem('token', token);
            return { token, isLoggedIn: true };
        }
        // ? Token is set to [error] to prevent infinite loop.
        return { token: false, isLoggedIn: false };
    }, []);

    const { data: userToken, isLoading: fetchingToken } = useQuery({
        queryKey: ['token'],
        queryFn: generateUserToken,
        staleTime: 24 * 60 * 60 * 1000,
    });

    useEffect(() => {
        if (!isReady) return;
        if (currentRouteRef.current) {
            router.push(currentRouteRef.current);
            currentRouteRef.current = undefined;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchingToken, isReady]);

    const { data: userData } = useQuery({
        queryKey: ['Auth', 'User'],
        queryFn: async () => {
            const response = await fetch(`${API_URL}/auth-service/api/auth/user/`, {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Token ${localStorage.getItem('token') || user?.token}`,
                },
                method: 'GET',
                credentials: 'include',
            });
            const res = await response.json();
            if (res?.id) {
                window.heap?.identify(res.username);
                hotjar.identify(
                    (res.username as string).replace('.com', '').replace(/[@.]/g, '-'),
                    {
                        groups: res.groups,
                        isArenaStaff: res.groups && res.groups.includes('arena-staff'),
                    },
                );

                const resDataFormatted = {
                    groups: res.groups,
                    username: res.username,
                    id: res.id,
                    showUserDetails: res.show_user_details_prompt,
                    firstName: res.first_name,
                    lastName: res.last_name,
                    ...res,
                };
                localStorage.setItem('user-data', JSON.stringify(resDataFormatted));
                setUser(prev => ({ ...prev, ...resDataFormatted }));
                return resDataFormatted;
            }
            return res;
        },
        enabled: !!userToken?.token,
        staleTime: 24 * 60 * 60 * 1000,
        retry: 2,
    });

    const verifyToken = React.useCallback(
        async (token?: string) => {
            if (!token) return;
            const response = await fetch(`${API_URL}/auth-service/api/auth/tokens/verify/`, {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Token ${token}`,
                },
                method: 'GET',
                credentials: 'include',
            });
            if (response.ok && token !== user?.token) {
                setUser(prev => ({ ...prev, token, isLoggedIn: true }));
                return;
            }
            if (response.status >= 400) {
                if (user?.token)
                    setUser(prev => ({ ...prev, token: undefined, isLoggedIn: false }));
                localStorage.removeItem('token');
            }
        },
        [user?.token],
    );

    const nextUrl = useMemo(() => {
        if (!isReady) return '';
        const DEFAULT_NEXT_URL = window.location.origin;
        return getNextUrl(DEFAULT_NEXT_URL + router.asPath);
    }, [isReady, router.asPath]);

    const googleLogin = useCallback(() => {
        location.href = `${API_URL}/auth-service/sso-v2/login/google-oauth2?next=${encodeURIComponent(
            nextUrl,
        )}`;
    }, [nextUrl]);

    const microsoftLogin = useCallback(() => {
        location.href = `${API_URL}/auth-service/sso-v2/login/azuread-oauth2?next=${encodeURIComponent(
            nextUrl,
        )}`;
    }, [nextUrl]);

    const credentialLogin = useCallback<AppContextType['credentialLogin']>(
        async ({ username, password }: { username: string; password: string }) => {
            const response = await fetch(`${API_URL}/auth-service/api/auth/login/`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    username,
                    password,
                }),
            });

            if (response.status >= 400) {
                const errorMassage = await response.json();
                return errorMassage?.detail;
            }

            const res: CredentialLogin = await response.json();
            setUser({
                groups: res.user.groups,
                username: res.user.username,
                id: res.user.id.toString(),
                showUserDetails: res.user.show_user_details_prompt,
                firstName: res.user.first_name,
                lastName: res.user.last_name,
            });
            generateUserToken();
            return 'success';
        },
        [generateUserToken],
    );
    const logOut = React.useCallback(async (token?: string) => {
        const response = await fetch(
            `${process.env.NEXT_PUBLIC_URL}/auth-service/api/auth/logout`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Token ${token}`,
                },
                method: 'GET',
                credentials: 'include',
            },
        );

        if (response.ok) {
            setUser(prev => ({ ...prev, isLoggedIn: false, token: undefined }));
            localStorage.removeItem('token');
            localStorage.removeItem('user-data');
        }
    }, []);

    useEffect(() => {
        // ? Other wise by the time we get userToken verify will override the token with undefined
        if (fetchingToken) return;
        const storedToken = localStorage.getItem('token');
        if (!storedToken && !user?.token) return;
        if (storedToken) verifyToken(storedToken);
        // Hack for incognito mode where localStorage might not be available
    }, [fetchingToken, user.token, verifyToken]);

    const value = React.useMemo(
        (): AppContextType => ({
            user: { ...user, ...userData },
            handleUser,
            verifyToken,
            googleLogin,
            microsoftLogin,
            credentialLogin,
            logOut,
            fetchingToken: fetchingToken && !user?.token,
        }),
        [
            user,
            userData,
            handleUser,
            verifyToken,
            googleLogin,
            microsoftLogin,
            credentialLogin,
            logOut,
            fetchingToken,
        ],
    );
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export const useAppContext = () => {
    const { handleUser, ...rest } = React.useContext(AppContext);

    if (!handleUser) {
        throw new Error('useAppContext must be used within an AppContextProvider');
    }
    return { handleUser, ...rest };
};

export default AppContextProvider;
export type { AppContextType };
