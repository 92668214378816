import {
    BracketsAngle,
    FileCode,
    GlobeHemisphereWest,
    Graph,
    Handshake,
    Package,
    Scroll,
    ShuffleAngular,
    Table,
    TrendUp,
    UserFocus,
} from 'phosphor-react';
import { ShoppingCart } from 'tabler-icons-react';
import { UserGroup } from '../../types/user';

export const OneBrainURL = 'https://docs.arena-ai.com/fusion';

export type BennuPagesType =
    | 'market-recap'
    | 'sales-assistant'
    | 'market-overview'
    | 'banner-assistant'
    | 'assortment-optimization';
export type ABIPagesType = 'pricing-intelligence';

export type ArenaPagesType = 'entity-resolution' | 'developer';

export const SIBERIA_PAGES = ['console', 'personalization'] as const;

export type SiberiaPagesType = (typeof SIBERIA_PAGES)[number];

export type AllPagesType =
    | '/'
    | 'consumers'
    | 'growth-actions'
    | 'integrations'
    | 'intelligence'
    | 'productSearch'
    | 'settings'
    | 'feedback'
    | 'market-performance'
    | 'context-module'
    | 'apis'
    | BennuPagesType
    | ArenaPagesType
    | ABIPagesType
    | SiberiaPagesType;

export type PagesType = AllPagesType;

export const PAGE_NAMES = {
    '/': 'Home',
    consumers: 'Consumers',
    'growth-actions': 'Growth Actions',
    integrations: 'Integrations',
    intelligence: 'Intelligence',
    productSearch: 'Products',
    settings: 'Settings',
    feedback: 'Feedback',
    'banner-assistant': 'Off-Trade Assortment',
    developer: 'Developer for Arena Staff',
    'market-recap': 'Nielsen Market Recap',
    'sales-assistant': 'On-Trade Assortment',
    'market-overview': 'Market Deep Dive',
    'pricing-intelligence': 'Pricing',
    'entity-resolution': 'Data Stitching & ER',
    'assortment-optimization': 'Assortment Optimization',
    'market-performance': 'Market Performance',
    'context-module': 'Store Context',
    apis: 'APIs & Documentation',
    'https://docs.arena-ai.com/fusion': 'OneBrain API',
};

const productFiltersParams = ['brand', 'size', 'pack', 'selections'];

export interface NavItem {
    href: PagesType | string;
    children: React.ReactNode;
    className?: string;
    routerQueryKeys?: string[];
    sharableKeys?: string[];
    shareRoutesQueryKeysWith?: Array<PagesType>;
    userGroupWhitelist?: UserGroup[] | UserGroup;
    userGroupBlacklist?: UserGroup[] | UserGroup;
}

export const NAV_ITEMS: NavItem[] = [
    {
        href: 'integrations',
        children: <ShuffleAngular size={30} strokeWidth={1} />,
        userGroupWhitelist: ['arena-staff', 'bennu-users'],
    },
    {
        href: OneBrainURL,
        children: <FileCode size={30} strokeWidth={1} />,
    },
    {
        href: 'entity-resolution',
        children: <Graph size={30} strokeWidth={1} />,
    },
    {
        href: 'productSearch',
        routerQueryKeys: [
            ...productFiltersParams,
            'states',
            'minDate',
            'maxDate',
            'view',
            'metric',
            'legends',
        ],
        sharableKeys: productFiltersParams,
        shareRoutesQueryKeysWith: ['growth-actions', 'consumers'],
        children: <ShoppingCart size={30} strokeWidth={1} />,
    },
    {
        href: 'consumers',
        routerQueryKeys: ['brand'],
        sharableKeys: ['brand'],
        shareRoutesQueryKeysWith: ['growth-actions', 'productSearch'],
        children: <UserFocus size={30} strokeWidth={1} />,
    },
    {
        href: 'pricing-intelligence',
        children: <Package size={30} strokeWidth={1} weight="light" />,
        userGroupWhitelist: ['arena-staff', 'abi-users'],
    },
    {
        href: 'assortment-optimization',
        children: <Handshake size={30} strokeWidth={1} />,
        userGroupWhitelist: ['arena-staff', 'bennu-users'],
    },
    /* {
        id: 'navbar-banner-assistant',
        href: 'banner-assistant',
        children: <Storefront size={30} strokeWidth={1} />,
        userGroupWhitelist: ['arena-staff', 'bennu-users'],
    }, */
    {
        href: 'market-recap',
        children: <Scroll size={30} strokeWidth={1} />,
        userGroupWhitelist: ['arena-staff', 'bennu-users'],
    },
    {
        href: 'market-overview',
        children: <Table size={30} strokeWidth={1} />,
        userGroupWhitelist: ['arena-staff', 'bennu-users'],
    },
    {
        href: 'market-performance',
        children: <TrendUp size={30} strokeWidth={1} />,
        userGroupWhitelist: ['arena-staff', 'bennu-users'],
    },
    {
        href: 'context-module',
        children: <GlobeHemisphereWest size={30} strokeWidth={1} />,
    },
    {
        href: 'developer',
        children: <BracketsAngle size={30} strokeWidth={1} />,
    },
];
