/* eslint-disable prefer-destructuring */

export const IS_PROD = process.env.VERCEL_ENV
    ? process.env.VERCEL_ENV !== 'development'
    : process.env.NODE_ENV !== 'development';
// export const API_URL = process.env.NEXT_PUBLIC_URL;
export const API_URL = IS_PROD
    ? 'https://fusion-prod.internal.arena-ai.com'
    : 'https://fusion-dev.internal.arena-ai.com';
export const HEAP_ID = process.env.NEXT_PUBLIC_HEAP_ID;
export const MAPBOX_ACCESS_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;
