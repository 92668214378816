import React from 'react';
import { AppShell, Image } from '@mantine/core';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Gear, Question } from 'phosphor-react';
import { ActiveLink } from './ActiveLink';
import { NAV_ITEMS, SIBERIA_PAGES } from './constants';

const NavItemsWrapper = ({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) => <div className={`flex flex-col ${className}`}>{children}</div>;

function NavBar() {
    const router = useRouter();

    return (
        <AppShell>
            <AppShell.Navbar
                // eslint-disable-next-line tailwindcss/no-custom-classname
                className="!glass-effect relative z-[400] flex !w-20 flex-col items-center gap-6 !bg-secondary-bg p-5"
                unstyled
            >
                <div>
                    <Link id="navbar-home" href="/">
                        <Image
                            src="images/favicon.png"
                            h={30}
                            alt="logo"
                            onClick={() => router.push('/')}
                            className="cursor-pointer hover:bg-shadow-300"
                        />
                    </Link>
                </div>
                <NavItemsWrapper className="grow justify-between">
                    <NavItemsWrapper className="max-h-[70dvh] grow gap-3 overflow-auto">
                        {NAV_ITEMS.filter(item => !SIBERIA_PAGES.includes(item.href as any)).map(
                            item => (
                                <ActiveLink key={item.href} {...item} />
                            ),
                        )}
                    </NavItemsWrapper>
                    <NavItemsWrapper className="mb-12 gap-3 border-t border-t-gray-600 pt-[6px]">
                        <>
                            <ActiveLink href="settings">
                                <Gear size={30} weight="light" />
                            </ActiveLink>
                            <ActiveLink href="feedback">
                                <Question size={30} strokeWidth={1} />
                            </ActiveLink>
                            <div className="absolute bottom-6 left-0">
                                <Image src="images/in_beta.svg" width={66} alt="beta" />
                            </div>
                        </>
                    </NavItemsWrapper>
                </NavItemsWrapper>
            </AppShell.Navbar>
        </AppShell>
    );
}

export default NavBar;
